import React from 'react'
import Modal from 'components/elements/Modal/Modal'
import ModalHeader from './ModalRecipientsHeader'
import ModalBody from './ModalRecipientsBody'
import ModalFooter from './ModalRecipientsFooter'
import styles from './ManageRecipientsModal.module.scss'

interface RecipientsModalProps {
  fileName: string
  isOpen: boolean
  handleModalClose: () => void
}

const ManageRecipientsModal = ({ fileName, isOpen, handleModalClose }: RecipientsModalProps) => {
  return (
    <Modal isOpen={isOpen} isRecipientsModal={true} contentWidth={'narrow'} handleModalClose={handleModalClose}>
      <div className={styles['modal-content-container']}>
        <ModalHeader fileName={fileName} />
        <ModalBody />
        <ModalFooter />
      </div>
    </Modal>
  )
}

export default ManageRecipientsModal
