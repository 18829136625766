import { usePreviewStore } from 'store'
import { useState } from 'react'
import styles from '../../Header/Header.module.scss'
import { ShareButton, ManageRecipientsModal } from '~elements'

const NavShareVideo = () => {
  const { selectedVideo } = usePreviewStore()
  const [manageRecipientsModalOpen, setManageRecipientsModalOpen] = useState<boolean>(false)

  const createShareUrl = (recordTitle: string): string => {
    //filePath construction
    return './fake-url.com'
  }

  const handleShare: React.MouseEventHandler = () => {
    try {
      //Share
      setManageRecipientsModalOpen(true)
      const shareUrl = createShareUrl(selectedVideo.fileName)
      console.log(`Sharing file ${selectedVideo.fileName}`)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <ShareButton displayText={'share'} handleShare={handleShare} styles={styles} />
      <ManageRecipientsModal
        fileName={selectedVideo.fileName}
        isOpen={manageRecipientsModalOpen}
        handleModalClose={() => setManageRecipientsModalOpen(false)}
      />
    </>
  )
}

export default NavShareVideo
