import styles from '../ListView.module.scss'
import { ShareButton } from '~elements'
import { FileRecord } from '../ListView'

interface IShareFileProps {
  record: FileRecord
  closeDropdownMenu: Function
  displayError: Function
  openManageRecipientsModal: Function
}

const ShareFile = ({ record, closeDropdownMenu, displayError, openManageRecipientsModal }: IShareFileProps) => {
  const createShareUrl = (recordTitle: string) => {
    //filePath construction
  }

  const handleShare: React.MouseEventHandler = () => {
    if (record.title) {
      try {
        //Share
        openManageRecipientsModal()
        console.log(`Sharing file ${record.title}`)
        closeDropdownMenu()
      } catch (error) {
        console.error(error)
        displayError(`Failed to share file ${record.title}`)
        closeDropdownMenu()
      }
    } else {
      closeDropdownMenu()
    }
  }

  return <ShareButton displayText={'share'} handleShare={handleShare} styles={styles} />
}

export default ShareFile
