import React from 'react'
import styles from './ManageRecipientsModal.module.scss'

const ModalFooter = () => {
  const handleCopyLink = () => {
    // Logic for copying the link
  }

  return (
    <div className={styles['modal-footer']}>
      <div className={styles['access-section']}>
        <div className={styles['item']}>
          <span className={styles['name']}>Domain Access</span>
          <span className={styles['number']}>0/3</span>
        </div>
        <div className={styles['item']}>
          <span className={styles['name']}>Vantage Point</span>
          <span className={styles['number']}>32/32</span>
        </div>
        <div className={styles['item']}>
          <span className={styles['name']}>Times of Day</span>
          <span className={styles['number']}>3/3</span>
        </div>
      </div>
      <div className={styles['divider']}></div>
      <div className={styles['footer-button']}>
        <button className={styles['copy-link-btn']} onClick={handleCopyLink}>
          Copy link
        </button>
        <button className={styles['share-btn']}>Share</button>
      </div>
    </div>
  )
}

export default ModalFooter
