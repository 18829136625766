import { EllipsisIcon, TheatersFillIcon } from 'components/elements/Icons'
import { IVideos } from 'components/modules/Dashboard'
import { capitalizeFirstLetter } from 'utils/files/helper'
import { useWindowSize } from 'utils/hooks'
import { PREVIEW_PAGE_BREAKPOINT } from '~constants'
import { formatDate, getInitialsByFNameLName } from '~utils'
import ShareFile from '../ShareFile'
import DownloadSelectedFile from '../DownloadFile'
import DeleteSelectedFile from '../DeleteFile'
import PreviewSelectedFile from 'components/elements/PreviewSelectedFile'
import styles from '../ListView.module.scss'
import React, { ForwardedRef, useRef, useState } from 'react'
import { ManageRecipientsModal, ExoVideoTitle } from '~elements'

interface SingleRecordProps {
  record: IVideos
  selectedRecord?: IVideos
  setGenericError: React.Dispatch<React.SetStateAction<string | null>>
  setDropdownOpen: (data: boolean) => void
  dropdownOpen: boolean
  setDeleteModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const SingleRecord = React.forwardRef((props: SingleRecordProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { record, selectedRecord, setGenericError, setDropdownOpen, dropdownOpen, setDeleteModalOpen } = props
  const { windowSize } = useWindowSize('lg', '<=')
  const isBelowBreakpointLg = windowSize <= PREVIEW_PAGE_BREAKPOINT
  const dropdownButtonRef = useRef<HTMLButtonElement | null>(null)
  const [manageRecipientsModalOpen, setManageRecipientsModalOpen] = useState<boolean>(false)

  const openManageRecipientsModal = () => {
    setManageRecipientsModalOpen(true)
  }

  return (
    <>
      <tr>
        <td>
          <div className={styles['title-container']}>
            <div>
              <TheatersFillIcon />
            </div>
            <div data-type="title">
              <ExoVideoTitle title={record.title} />
            </div>
          </div>
        </td>
        {!isBelowBreakpointLg && (
          <>
            <td>{`${record.filesize} MB`}</td>
            <td>{formatDate(record.uploaded_on)}</td>
            <td>
              <div className={styles['uploaded-by-initials-container']}>
                <div className={styles['uploaded-by-initials']}>{getInitialsByFNameLName(record.firstname, record.lastname)}</div>
                <div className={styles['uploaded-by-name']}>
                  {`${capitalizeFirstLetter(record.firstname)} ${capitalizeFirstLetter(record.lastname)}`}
                </div>
              </div>
            </td>
          </>
        )}
        <td>
          <div className={styles['container']}>
            <button
              className={styles['dropdown-button']}
              value={record.title}
              ref={dropdownButtonRef}
              id={`dropdown-button`}
              data-testid="more-button"
            >
              <EllipsisIcon />
            </button>
            {dropdownOpen && selectedRecord?.title === record.title ? (
              <div className={styles['dropdown-menu']} ref={ref}>
                <ShareFile
                  record={record}
                  closeDropdownMenu={() => setDropdownOpen(false)}
                  displayError={setGenericError}
                  openManageRecipientsModal={openManageRecipientsModal}
                />
                <DownloadSelectedFile record={record} closeDropdownMenu={() => setDropdownOpen(false)} displayError={setGenericError} />
                <DeleteSelectedFile openDeleteModal={setDeleteModalOpen} closeDropdownMenu={() => setDropdownOpen(false)} />
              </div>
            ) : null}
            <PreviewSelectedFile record={record} displayError={setGenericError} />
          </div>
        </td>
      </tr>
      <ManageRecipientsModal
        fileName={record.title}
        isOpen={manageRecipientsModalOpen}
        handleModalClose={() => setManageRecipientsModalOpen(false)}
      />
    </>
  )
})

SingleRecord.displayName = 'SingleRecord'

export default SingleRecord
