import { ShareIcon } from '~elements'

interface ShareButtonProps {
  displayText: string
  handleShare: Function
  styles: {
    readonly [key: string]: string
  }
}

const ShareButton = ({ styles, handleShare, displayText }: ShareButtonProps) => {
  return (
    <button
      onClick={() => {
        handleShare()
        console.log('sharing!')
      }}
      className={styles['share-button']}
      data-testid={'share-button'}
    >
      <ShareIcon />
      <div className={styles['item-display-name']}>{displayText}</div>
    </button>
  )
}

export default ShareButton
